export type Vec2 = { x: number; y: number };

export function toVector(pos: { x: number; y: number }): Vector2 {
  return new Vector2(pos.x, pos.y);
}

export class Vector2 implements Vec2 {
  x = 0;
  y = 0;
  public get magnitude(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }
  constructor();
  constructor(vector: Readonly<Vec2>);
  constructor(x: number, y: number);
  constructor(...arr: any[]) {
    if (arr.length == 1) {
      this.x = arr[0].x;
      this.y = arr[0].y;
    } else if (arr.length == 2) {
      this.x = arr[0];
      this.y = arr[1];
    }
  }

  distance(other: Vec2): number {
    return distance(this, other);
  }

  clampMagnitude(maxLength: number) {
    clampMagnitude(this, maxLength);
  }

  normalize() {
    normalize(this, this);
  }
  scale(scale: number) {
    this.x = this.x * scale;
    this.y = this.y * scale;
  }
  negate() {
    this.x = -this.x;
    this.y = -this.y;
  }
}

export function add(vec1: Vec2, vec2: Vec2, out?: Vec2): Vec2 {
  out = out ?? new Vector2();
  out.x = vec1.x + vec2.x;
  out.y = vec1.y + vec2.y;
  return out;
}

export function subtract(vec1: Vec2, vec2: Vec2, out?: Vec2): Vec2 {
  out = out ?? new Vector2();
  out.x = vec1.x - vec2.x;
  out.y = vec1.y - vec2.y;
  return out;
}

export function fromTo(vec1: Vec2, vec2: Vec2, out?: Vec2): Vec2 {
  out = out ?? new Vector2();
  out.x = vec2.x - vec1.x;
  out.y = vec2.y - vec1.y;
  return out;
}

export function multiply(vec1: Vec2, vec2: Vec2, out?: Vec2): Vec2 {
  out = out ?? new Vector2();
  out.x = vec1.x * vec2.x;
  out.y = vec1.y * vec2.y;
  return out;
}

export function scale(vec: Vec2, scalar: number, out?: Vec2): Vec2 {
  out = out ?? new Vector2();
  out.x = vec.x * scalar;
  out.y = vec.y * scalar;
  return out;
}

export function normalize(vec: Vec2, out?: Vec2): Vec2 {
  const mag = magnitude(vec);
  out = out ?? new Vector2(vec.x, vec.y);

  if (mag > Number.EPSILON) {
    out.x /= mag;
    out.y /= mag;
  } else {
    out.x = 0;
    out.y = 0;
  }
  return out;
}

export function dot(vec1: Vec2, vec2: Vec2): number {
  return vec1.x * vec2.x + vec1.y * vec2.y;
}

export function perpendicular(vector: Vec2, out?: Vec2): Vec2 {
  out = out ?? new Vector2();
  out.x = -vector.y;
  out.y = vector.x;
  return out;
}

export function negate(vector: Vec2, out?: Vec2): Vec2 {
  out = out ?? new Vector2();
  out.x = -vector.x;
  out.y = -vector.y;
  return out;
}

export function magnitude(vec: Vec2): number {
  return Math.sqrt(vec.x * vec.x + vec.y * vec.y);
}

export function squaredMagnitude(vec: Vec2): number {
  return vec.x * vec.x + vec.y + vec.y;
}

export function clampMagnitude(vector: Vec2, maxLength: number): void {
  const magnitudeSquared = vector.x * vector.x + vector.y * vector.y;
  if (magnitudeSquared > maxLength * maxLength) {
    const magnitude = Math.sqrt(magnitudeSquared);
    const ratio = maxLength / magnitude;
    vector.x = vector.x * ratio;
    vector.y = vector.y * ratio;
  }
}

export function distance(vec1: Vec2, vec2: Vec2): number {
  const deltaX = vec2.x - vec1.x;
  const deltaY = vec2.y - vec1.y;
  return Math.hypot(deltaX, deltaY);
}

export function distanceSquared(vec1: Vec2, vec2: Vec2): number {
  const deltaX = vec2.x - vec1.x;
  const deltaY = vec2.y - vec1.y;
  return deltaX * deltaX + deltaY * deltaY;
}

export function angleBetweenVectors(vector1: Vec2, vector2: Vec2) {
  const dotProduct = dot(vector1, vector2);
  const mag1 = magnitude(vector1);
  const mag2 = magnitude(vector2);
  const cosAngle = dotProduct / (mag1 * mag2);
  const angleInRadians = Math.acos(cosAngle);
  return angleInRadians;
}

export function areEqual(vector1: Vec2, vector2: Vec2): boolean {
  return vector1.x == vector2.x && vector1.y == vector2.y;
}

export function lerp(a: Vec2, b: Vec2, t: number, out?: Vec2): Vec2 {
  out = out ?? { x: 0, y: 0 };
  out.x = a.x + (b.x - a.x) * t;
  out.y = a.y + (b.y - a.y) * t;
  return out;
}

export function slerp(start: Vec2, end: Vec2, t: number, out?: Vec2): Vec2 {
  out = out ?? { x: 0, y: 0 };
  const dot = start.x * end.x + start.y * end.y;
  const theta = Math.acos(dot);
  const sinTheta = Math.sin(theta);
  const t1 = Math.sin((1 - t) * theta) / sinTheta;
  const t2 = Math.sin(t * theta) / sinTheta;
  out.x = start.x * t1 + end.x * t2;
  out.y = start.y * t1 + end.y * t2;
  return out;
}
